import { Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import FallbackLoader from './components/FallbackLoader';
import { useGetGeneralQuery, useLazyGetGeneralQuery } from './redux/Apis/General';
import Router from './router';

import MessengerCustomerChat from 'react-messenger-customer-chat';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useGetBannerQuery } from './redux/Apis/Banner';
import AgeRestriction from './components/AgeRestriction';
import ScrollToTopIcon from './components/ScrollToTopIcon';

console.log = () => { }
console.error = () => { }
console.warn = () => { }

function App() {

  const { data } = useGetGeneralQuery({}, { refetchOnFocus: true })
  const { } = useGetBannerQuery({}, { refetchOnFocus: true })
  const [get] = useLazyGetGeneralQuery()

  useEffect(() => {
    get()
  }, [])

  return (
    <>
      <ToastContainer pauseOnHover={false} />
      <FloatingWhatsApp
        accountName='Vapebae Support'
        phoneNumber="+14709308814"
        avatar={require("./assets/images/logo.png")}
        buttonStyle={{ left: "2rem" }}
      />
      <ScrollToTopIcon />
      {/* <MessengerCustomerChat
        pageId="233161193206159"
        appId="1142041597242435"
      /> */}
      < Suspense fallback={< FallbackLoader />}>
        <Router />
        <AgeRestriction />
      </Suspense >
    </>
  )
}

export default App;
