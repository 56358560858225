import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { toast } from "react-toastify"
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { authApiService } from "../Apis/Auth"
import { userApiService } from "../Apis/User"

import { brandsApiService } from "../Apis/Brands"
import { categoryApiService } from "../Apis/Category"
import { productApiService } from "../Apis/Product"
import { subcategoryApiService } from "../Apis/Subcategory"
import AuthSlice, { logout } from "../Slices/Auth"
import GeneralSlice from "../Slices/General"
import { generalApiService } from "../Apis/General"
import { imageApiService } from "../Apis/Image"
import { businessApiService } from "../Apis/Business"
import { orderApiService } from "../Apis/Order"
import { cartApiService } from "../Apis/Cart"
import { bannerApiService } from "../Apis/Banner"
import { reminderApiService } from "../Apis/Reminder"

const apiErrorHandler = (store) => (next) => (action) => {
    if (action.type.endsWith('/rejected')) {
        if (action && action.payload) {
            let { data, status } = action.payload
            let { message } = data

            toast.error(message)

            if (status === 500) {
                store.dispatch(logout())
            }
        }
    }

    return next(action);
};

const persistConfig = {
    key: 'root',
    storage: storage
}

export const rootReducers = combineReducers({
    authSlice: AuthSlice,
    generalSlice: GeneralSlice,
    [authApiService.reducerPath]: authApiService.reducer,
    [userApiService.reducerPath]: userApiService.reducer,
    [brandsApiService.reducerPath]: brandsApiService.reducer,
    [categoryApiService.reducerPath]: categoryApiService.reducer,
    [productApiService.reducerPath]: productApiService.reducer,
    [subcategoryApiService.reducerPath]: subcategoryApiService.reducer,
    [generalApiService.reducerPath]: generalApiService.reducer,
    [imageApiService.reducerPath]: imageApiService.reducer,
    [businessApiService.reducerPath]: businessApiService.reducer,
    [orderApiService.reducerPath]: orderApiService.reducer,
    [cartApiService.reducerPath]: cartApiService.reducer,
    [bannerApiService.reducerPath]: bannerApiService.reducer,
    [reminderApiService.reducerPath]: reminderApiService.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    })
        .concat(authApiService.middleware)
        .concat(userApiService.middleware)
        .concat(brandsApiService.middleware)
        .concat(categoryApiService.middleware)
        .concat(productApiService.middleware)
        .concat(subcategoryApiService.middleware)
        .concat(generalApiService.middleware)
        .concat(imageApiService.middleware)
        .concat(businessApiService.middleware)
        .concat(orderApiService.middleware)
        .concat(cartApiService.middleware)
        .concat(bannerApiService.middleware)
        .concat(reminderApiService.middleware)
        .concat(apiErrorHandler)
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)