import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyGetProductsQuery } from '../../redux/Apis/Product';
import './index.css';

const ProductSearch = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const [getProducts, { data }] = useLazyGetProductsQuery()

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    getProducts({ search: term, currentPage: 1, itemsPerPage: 3 })
    setShowDropdown(term.length > 0);
  };

  return (
    <div>
      <div className="search-bar">
        <i className="fas fa-search p-lg green-text left-icon" />
        <input
          type="text"
          placeholder="Search"
          className="site-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      {showDropdown && (
        <div className="dropdown search-dropdown">
          {data?.data?.map((product) => (
            <div key={product.id} className="d-flex align-items-center w-400 gap-3">
              <div className="flex-shrink-0">
                <img src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${product?.images[0]}`} alt="" className="img-fluid table-img" />
              </div>
              <div className="flex-grow-1">
                <Link to={`/products/${product?._id}`} onClick={() => setShowDropdown(false)}>
                  <p className="p-md mb-0 medium">
                    {/* {data?.name} */}
                    {product.name}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSearch;