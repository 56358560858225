import { lazy } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import Footer from "../layouts/Footer"
import Header from "../layouts/Header"
import MyOrders from "../pages/MyOrders"
import OrderDetails from "../pages/OrderDetails"
import PrivateRoute from "./PrivateRoute"
import ScrollToTop from '../components/ScrollToTop'

const Login = lazy(() => import("../pages/Login"))
const Register = lazy(() => import("../pages/Register"))
const Brands = lazy(() => import("../pages/Brands"))
const Products = lazy(() => import("../pages/Products"))
const ForgetPassword = lazy(() => import("../pages/ForgetPassword"))
const VerifyCode = lazy(() => import("../pages/VerifyCode"))
const SetPassword = lazy(() => import("../pages/SetPassword"))
const Profile = lazy(() => import("../pages/Profile"))
const EditProfile = lazy(() => import("../pages/EditProfile"))
const ChangePassword = lazy(() => import("../pages/ChangePassword"))
const Cart = lazy(() => import("../pages/Cart"))
const Checkout = lazy(() => import("../pages/Checkout"))
const ProductDetails = lazy(() => import("../pages/ProductDetails"))
const Home = lazy(() => import("../pages/Home"))
const NotFound = lazy(() => import("../pages/NotFound"))
const AuthLayout = lazy(() => import("../layouts/AuthLayout"))

const Router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="auth/" element={<AuthLayout />}>
                    <Route exact path="login" element={<Login />} />
                    <Route exact path="register" element={<Register />} />
                    <Route exact path="forget-password" element={<ForgetPassword />} />
                    <Route exact path="verify" element={<VerifyCode />} />
                    <Route exact path="set-password" element={<SetPassword />} />
                </Route>
                <Route exact path="/brands" element={<Brands />} />
                <Route exact path="/products" element={<Products />} />
                <Route exact path="/products/:id" element={<ProductDetails />} />
                <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route exact path="/edit-profile" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
                <Route exact path="/change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
                <Route exact path="/cart" element={<PrivateRoute><Cart /></PrivateRoute>} />
                <Route exact path="/my-orders" element={<MyOrders />} />
                <Route exact path="/my-orders/:id" element={<OrderDetails />} />
                <Route exact path="/checkout" element={<PrivateRoute><Checkout /></PrivateRoute>} />
                <Route exact path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default Router
