import Breadcrumbs from "../../components/Breadcrumbs"
import NumberInput from "../../components/NumberInput"
import OverlayLoader from "../../components/OverlayLoader"
import useOrderDetailsController from "./useOrderDetailsController"

const OrderDetails = () => {

    const { values } = useOrderDetailsController()

    console.log("dadda", values?.data)

    return (
        <>
            <div className="site-separator" />
            {/* banner section starts here */}
            <section className="inner-banner-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumbs
                                breadcrumbs={[{ label: "Home", link: "/" }, { label: "My orders", link: "/my-orders" }, { label: "Details" }]}
                            />
                            <h3 className="semi-bold">Order Details</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* banner section ends here */}
            {/* all products section starts here */}
            <section className="all-products">
                <div className="container">
                    <div className="row my-2">
                        <div className="col-4">
                            <div className="flex-grow-1">
                                <p className="p-sm bold mb-0">Order #</p>
                                <p className="p-md mb-0">{values?.data?.trackingNumber}</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="flex-grow-1">
                                <p className="p-sm bold mb-0">Payment Type</p>
                                <p className="p-md mb-0 text-capitalize">{values?.data?.paymentVia}</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="flex-grow-1">
                                <p className="p-sm bold mb-0">Total Amount</p>
                                <p className="p-md mb-0 text-capitalize">$ {values?.data?.total}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h4 className="semi-bold">Contact Details</h4>
                    </div>
                    <div className="row my-2">
                        <div className="col-4">
                            <div className="flex-grow-1">
                                <p className="p-sm bold mb-0">Name</p>
                                <p className="p-md mb-0">{values?.data?.contactDetails?.name}</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="flex-grow-1">
                                <p className="p-sm bold mb-0">Email</p>
                                <p className="p-md mb-0">{values?.data?.contactDetails?.email}</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="flex-grow-1">
                                <p className="p-sm bold mb-0">Phone</p>
                                <p className="p-md mb-0">{values?.data?.contactDetails?.phone}</p>
                            </div>
                        </div>
                    </div>
                    {
                        values?.data?.paymentVia !== "contact" &&
                        <>
                            <div className="row my-2">
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">City</p>
                                        <p className="p-md mb-0">{values?.data?.contactDetails?.city}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">State</p>
                                        <p className="p-md mb-0">{values?.data?.contactDetails?.state}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Zip</p>
                                        <p className="p-md mb-0">{values?.data?.contactDetails?.zip}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-12">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Address</p>
                                        <p className="p-md mb-0">{values?.data?.contactDetails?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        values?.data?.paymentVia === "cheque" &&
                        <>
                            <div className="row">
                                <h4 className="semi-bold">Bank Details</h4>
                            </div>
                            <div className="row my-2">
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Cheque Number</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.chequeNumber}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Cheque Payee Name</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.chequePayeeName}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Cheque Date</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.chequeDate}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Name</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.name}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Email</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.email}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Phone</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.phone}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Routing Number</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.routingNumber}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Fraction</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.fraction}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Cheque Amount</p>
                                        <p className="p-md mb-0">$ {values?.data?.bankDetails?.chequeAmount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">City</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.city}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">State</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.state}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-grow-1">
                                        <p className="p-sm bold mb-0">Zip</p>
                                        <p className="p-md mb-0">{values?.data?.bankDetails?.zip}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="row">
                        <h4 className="semi-bold">Items</h4>
                    </div>
                    <div className="row">
                        <div className="table-responsive cart-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th className="text-center">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        values?.data?.items?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="d-flex align-items-center w-400 gap-3">
                                                        <div className="flex-shrink-0">
                                                            <img
                                                                src={process.env.REACT_APP_IMAGE_ENDPOINT + item?.product?.images?.[0]}
                                                                alt=""
                                                                className="img-fluid table-img"
                                                            />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <p className="p-md mb-0 medium">
                                                                {item?.product?.name}
                                                            </p>
                                                            <p className="p-xs mb-0">{item.variation.name}</p>
                                                            {/* {
                                                                item?.items?.map(e => (
                                                                    e.selectedOptions?.map(el => (
                                                                    ))
                                                                ))
                                                            } */}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <NumberInput
                                                        value={item.qty}
                                                        readOnly
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <h4>${item.price}</h4>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <OverlayLoader loading={values.loading} />
        </>
    )
}

export default OrderDetails