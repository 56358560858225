import { useParams } from "react-router-dom"
import { useGetOrderQuery } from "../../redux/Apis/Order"

const useOrderDetailsController = () => {

    const { id } = useParams()
    const { data, isLoading } = useGetOrderQuery({ id })

    return {
        values: {
            data: data?.data,
            loading: isLoading
        },
        functions: {
        }
    }

}

export default useOrderDetailsController