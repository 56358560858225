import { useCallback } from "react"

const NumberInput = ({ value, onChange, readOnly }) => {

    const onDecrement = useCallback(() => {
        if (!readOnly) {
            if (value > 0) {
                onChange(value - 1)
            }
        }
    }, [value])

    const onIncrement = useCallback(() => {
        if (!readOnly) {
            onChange(value + 1)
        }
    }, [value])

    return (
        <div className={`quantity-container justify-content-${readOnly ? "center" : "between"}`}>
            {!readOnly && <button onClick={onDecrement}>-</button>}
            <input
                type="text"
                id="quantity6"
                value={value}
                onChange={e => {
                    if (!readOnly) {
                        onChange(e.target.value)
                    }
                }}
                readOnly={readOnly}
            />
            {!readOnly && <button onClick={onIncrement}>+</button>}
        </div>
    )
}

export default NumberInput