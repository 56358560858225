import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/product/`

export const productApiService = createApi({
    reducerPath: 'productApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token

            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }

            return headers
        }
    }),
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (args) => {
                let { id, currentPage, itemsPerPage, brand, subcategory, type, min, max, search, sortBy } = args

                if (id) {
                    return `get/${id}`
                }

                return `get?page=${currentPage}&rowsPerPage=${itemsPerPage}${brand && brand !== '' ? `&brand=${brand}` : ""}${subcategory && subcategory !== '' ? `&subcategory=${subcategory}` : ""}${type && type !== '' ? `&type=${type}` : ""}${min && min !== '' ? `&min=${min}` : ""}${max && max > 0 ? `&max=${max}` : ""}${search && search !== "" ? `&search=${search}` : ""}${sortBy && sortBy !== "" ? `&sortBy=${sortBy}&sort=1` : ""}`
            },
            keepUnusedDataFor: 0
        })
    })
})

export const {
    useGetProductsQuery,
    useLazyGetProductsQuery
} = productApiService