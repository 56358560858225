import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AgeRestriction = () => {
  const [showModal, setShowModal] = useState(true);
  const [under21, setUnder21] = useState(false);
  useEffect(() => {
    if (showModal || under21) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal, under21]);
  function handleUnder21() {
    setUnder21(true);
  }
  if (showModal && !under21) {
    return (
      <>
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src="../../assets/images/exclamation.png" className="mb-3" style={{height: '60px', width: '60px'}}/>
                  <p className="p-md semi-bold">
                    YOU MUST BE 21 YEARS OF AGE OR OLDER TO ENTER
                  </p>
                  <p className="p-sm" style={{marginTop: '-10px'}}>
                    This Product Contains Nicotine.<br/>Nicotine Is An Addictive
                    Chemical.
                  </p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                  <button
                    className="btn btn-success site-btn"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Yes, I'm 21+
                  </button>
                  <button
                    className="btn btn-danger site-btn red-btn"
                    type="button"
                    onClick={handleUnder21}
                  >
                    No, I'm under 21
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (under21) {
    return (
      <>
        <div
          className="modal-backdrop fade show"
          style={{ display: "block" }}
        ></div>
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src="../../assets/images/exclamation.png" className="mb-3" style={{height: '60px', width: '60px'}}/>
                <p className="p-md semi-bold">SORRY</p>
                <p className="p-sm" style={{marginTop: '-10px'}}>
                  You must be 21+ years of age or older & of the legal smoking
                  age in your state to visit the “VapeBae” website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else return null;
};

export default AgeRestriction;
