import { useNavigate } from "react-router-dom"
import { useGetOrderQuery } from "../../redux/Apis/Order"

const useMyOrderController = () => {

    const navigate = useNavigate()
    const { data, isLoading } = useGetOrderQuery()

    const onClickOrder = id => {
        navigate("/my-orders/" + id)
    }

    return {
        values: {
            data: data?.data,
            loading: isLoading
        },
        functions: {
            onClickOrder
        }
    }

}

export default useMyOrderController