import Breadcrumbs from "../../components/Breadcrumbs"
import OverlayLoader from "../../components/OverlayLoader"
import useMyOrdersController from "./useMyOrdersController"

const MyOrders = () => {

    const { values, functions } = useMyOrdersController()

    return (
        <>
            <div className="site-separator" />
            {/* banner section starts here */}
            <section className="inner-banner-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumbs
                                breadcrumbs={[{ label: "Home", link: "/" }, { label: "My orders" }]}
                            />
                            <h3 className="semi-bold">My Orders</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* banner section ends here */}
            {/* all products section starts here */}
            <section className="all-products">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {
                                values?.data && values?.data.length > 0 ?
                                    <>
                                        <div className="table-responsive cart-table mt-4">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Order #</th>
                                                        <th>Payment Via</th>
                                                        <th>Status</th>
                                                        <th>Order Date</th>
                                                        <th className="text-center">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        values.data.map((item, index) => (
                                                            <tr className="cursor-pointer" key={index} onClick={() => functions.onClickOrder(item._id)}>
                                                                <td className="text-capitalize">
                                                                    <p className="m-0">{item?.trackingNumber || "0"}</p>
                                                                </td>
                                                                <td className="text-capitalize">
                                                                    <p className="m-0">{item.paymentVia}</p>
                                                                </td>
                                                                <td className="text-capitalize">
                                                                    <p className="m-0">{item.status}</p>
                                                                </td>
                                                                <td className="text-capitalize">
                                                                    <p className="m-0">{item.createdAt.split("T")[0]}</p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <h4>$ {Number(item.total).toFixed(2)}</h4>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </> :
                                    <h3 className="semi-bold text-center">No Orders</h3>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <OverlayLoader loading={values.loading} />
        </>
    )
}

export default MyOrders