import { Link } from "react-router-dom"

const Breadcrumbs = ({ breadcrumbs = [] }) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
                {
                    breadcrumbs.map((item, index) => (
                        <li className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? "active" : ""}`} {...(index == breadcrumbs.length - 1) ? { "aria-current": "page" } : {}}>
                            {
                                index === breadcrumbs.length - 1 ? item.label : <Link to={item.link}>{item.label}</Link>
                            }
                        </li>
                    ))
                }
            </ol>
        </nav>
    )
}

export default Breadcrumbs