import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';

import { logout } from "../../redux/Slices/Auth";
import { changeFilter, emptyCart } from "../../redux/Slices/General";
import MegaMenu from "./MegaMenu";
import ProductSearch from "../../components/ProductSearch";
import SlideProductSearch from "../../components/SlideProductSearch";

const Header = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isLoggedIn } = useSelector(state => state.authSlice)
    const { general, cart, filters } = useSelector(state => state.generalSlice)

    const [isMegaMenuVisible, setIsMegaMenuVisible] = useState(false);

    const handleToggleMegaMenu = () => {
        setIsMegaMenuVisible((prevVisibility) => !prevVisibility);
    };

    const onSignout = useCallback(() => {
        dispatch(emptyCart())
        dispatch(logout())
    }, [])

    const onProfile = useCallback(() => {
        navigate('/profile')
    }, [])

    const onOrders = useCallback(() => {
        navigate('/my-orders')
    }, [])

    const onClick = (value) => {
        dispatch(changeFilter({ subcategory: value }))
        navigate("/products")
    }

    const onClickSearch = () => {
        var $t = $("#search-panel");
        if ($t.is(':visible')) {
            $t.slideUp();
            $(".header")
                .delay(500)
                .queue(function (next) {
                    $(this).css("border-bottom", "1px solid #eeeeee");
                    next();
                });
        }
        else {
            $t.slideDown();
            $(".header").css("border-bottom", "1px solid #3498db");
            $('#search-field').focus();
        }
    }

    return (
        <header className="header-loggedin">
            <div className="header-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                <div className="flex-shrink-0 gap-3 d-flex align-items-center">
                                    <div className="flex-shrink-0 d-none d-sm-flex align-items-center gap-2">
                                        <a href="https://www.facebook.com/profile.php?id=61555993592562&mibextid=LQQJ4d" target="_blank">
                                            <img
                                                src="../assets/images/facebook.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </a>
                                        <a href="https://www.instagram.com/vapebaeus?igsh=aHZoemVmb2I4Ympn&utm_source=qr" target="_blank">
                                            <img
                                                src="../assets/images/instagram.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </a>
                                    </div>
                                    <div className="flex-grow-1 align-items-center d-flex gap-1">
                                        <p className="mb-0 green-text">Call Us Today:</p>
                                        <a href="tel:+14709308814" className="green-text">
                                            +1 (470) 930-8814
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-grow-1 d-lg-block d-none text-center">
                                    <p className="mb-0">
                                        At Vapebae We Source Products From Multiple Warehouses So You
                                        Can Get AccessTo Everything You Need At One Place.
                                    </p>
                                </div>
                                <div className="flex-shrink-0 d-flex align-items-center gap-2">
                                    {
                                        !isLoggedIn &&
                                        <div className="d-flex align-items-center gap-1">
                                            <img
                                                src={`../assets/images/sign-in.png`}
                                                alt=""
                                                className="img-fluid"
                                            />
                                            <Link to="/auth/register">Register</Link>
                                        </div>
                                    }
                                    <div className="d-flex align-items-center gap-1">
                                        <img
                                            src={`../assets/images/sign-${isLoggedIn ? "out" : "in"}.png`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                        {
                                            isLoggedIn ? (
                                                <Link onClick={onSignout}>Sign Out</Link>
                                            ) : (
                                                <Link to="/auth/login">Sign In</Link>
                                            )
                                        }
                                    </div>
                                    <div className="d-flex align-items-center gap-1">
                                        <img
                                            src="../assets/images/cart.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                        <Link to="/cart">Cart</Link>
                                        <span className="count-tag">{cart || "0"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <nav className="navbar navbar-expand-lg">
                            <Link className="navbar-brand" to="/">
                                <img src="../assets/images/logo.png" alt="" className="img-fluid" />
                            </Link>
                            <button
                                className="navbar-toggler transparent-btn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNav"
                                aria-controls="navbarNav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fas fa-bars" />
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav mx-auto gap-xl-3 gap-2 flex-grow-1 justify-content-lg-center align-items-lg-center">
                                    {
                                        general?.categories?.map((item, index) => (
                                            <MegaMenu key={index} title={item.label} content={
                                                <div className="w-100">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <ul className="ul-reset">
                                                                {/* <p className="bold mb-0 mt-3">{item.label}</p> */}
                                                                {
                                                                    item?.subcategories?.map(item => (
                                                                        <li className="cursor-pointer">
                                                                            <a onClick={() => onClick(item.value)} className={`mega-menu-item ${filters?.subcategory == item.value ? "selected-item" : ""}`}>{item.label}</a>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                        {/* {
                                                                    general?.categories?.map((item, index) => (
                                                                        
                                                                    ))
                                                                } */}
                                                        {/* <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">AMS SMOKE</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">Delta CBD</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">KRATOM</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">VAPE</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">AMS SMOKE</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">Delta CBD</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">KRATOM</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">VAPE</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">AMS SMOKE</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">Delta CBD</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">KRATOM</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <ul className="ul-reset">
                                                                        <p className="bold mb-0 mt-3">VAPE</p>
                                                                        <li>
                                                                            <a href="#">Cigaratte</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Disposables</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Drink &amp; More</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Wax</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#">Prerolls</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            } />
                                        ))
                                    }
                                </ul>
                                {
                                    isLoggedIn ? (
                                        <ul className="nav navbar-nav align-items-center ms-auto mt-3 gap-4 mt-lg-0 flex-row">
                                            <li className="nav-item">
                                                <span className="search-main" onClick={onClickSearch}>
                                                    <a href="#">
                                                        <i className="fa fa-search" aria-hidden="true" />
                                                    </a>
                                                </span>
                                                <SlideProductSearch />
                                                
                                            </li>
                                            <li className="nav-item">
                                                <div className="btn-group header-dropdown">
                                                    <button
                                                        type="button"
                                                        className="header-dropdown-btn"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            src="../assets/images/img-placeholder.png"
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        <span className="user-name p-xs medium">{user?.fullName || "John Doe"}</span>
                                                        <i className="fas fa-chevron-down p-xs ms-2" />
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={onProfile}
                                                            >
                                                                Profile
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={onOrders}
                                                            >
                                                                My Orders
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul className="nav navbar-nav align-items-center ms-auto mt-3 mt-lg-0 flex-row">
                                            <li className="nav-item">
                                                <ProductSearch />
                                                {/* <div className="search-bar">
                                                    <i className="fas fa-search p-lg green-text left-icon" />
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        className="site-input"
                                                    />
                                                </div> */}
                                            </li>
                                        </ul>
                                    )
                                }

                            </div>
                        </nav>
                    </div>
                </div >
            </div >
        </header >
    )
}

export default Header