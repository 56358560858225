import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/general/`

export const generalApiService = createApi({
    reducerPath: 'generalApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token

            headers.set('Authorization', `Bearer ${token}`)

            return headers
        }
    }),
    endpoints: (builder) => ({
        getGeneral: builder.query({
            query: (args) => 'get',
            keepUnusedDataFor: 0
        }),
        getRepresentatives: builder.query({
            query: () => 'representatives-lov',
            keepUnusedDataFor: 0
        }),
    })
})

export const {
    useGetGeneralQuery,
    useLazyGetGeneralQuery,
    useGetRepresentativesQuery
} = generalApiService