import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/order/`

export const orderApiService = createApi({
    reducerPath: 'orderApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token

            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }

            return headers
        }
    }),
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (payload) => ({
                url: "create",
                method: "POST",
                body: payload
            })
        }),
        getOrder: builder.query({
            query: (args) => {
                if (args && args?.id) {
                    return `get/${args?.id}`
                }

                return "get"

            },
            keepUnusedDataFor: 0
        })
    })
})

export const {
    useCreateOrderMutation,
    useGetOrderQuery
} = orderApiService