import React, { useState } from 'react';

const MegaMenu = ({ title, content }) => {
    const [isMegaMenuVisible, setIsMegaMenuVisible] = useState(false);

    const handleToggleMegaMenu = () => {
        setIsMegaMenuVisible((prevVisibility) => !prevVisibility);
    };

    return (
        <li className="droppable" style={{ position: 'relative' }}>
            <a href="#" onClick={handleToggleMegaMenu}>
                {title} <i className="fas fa-chevron-down p-sm" />
            </a>
            <div className={`mega-menu ${isMegaMenuVisible ? 'mega-menu-show' : ''}`}>
                {content}
            </div>
        </li>
    );
};

export default MegaMenu;