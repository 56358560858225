const OverlayLoader = ({ loading }) => {

    if (!loading) {
        return null
    }

    return (
        <div id="ctn-preloader" className="ctn-preloader" style={{ backgroundColor: 'rgba(189, 195, 199,0.6)', overflowX: 'hidden' }}>
            <div className="animation-preloader">
                <div className="spinner" />
                <img
                    src="../assets/images/logo.png"
                    alt="logo"
                    className="img-fluid loader-logo"
                />
                <div className="txt-loading">
                    <span data-text-preloader="L" className="letters-loading">
                        L
                    </span>
                    <span data-text-preloader="O" className="letters-loading">
                        O
                    </span>
                    <span data-text-preloader="A" className="letters-loading">
                        A
                    </span>
                    <span data-text-preloader="D" className="letters-loading">
                        D
                    </span>
                    <span data-text-preloader="I" className="letters-loading">
                        I
                    </span>
                    <span data-text-preloader="N" className="letters-loading">
                        N
                    </span>
                    <span data-text-preloader="G" className="letters-loading">
                        G
                    </span>
                </div>
            </div>
        </div>
    )
}

export default OverlayLoader