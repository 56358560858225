import { useEffect, useState } from "react";

const ScrollToTopIcon = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > window.innerHeight) { // Compare with window.innerHeight
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        isVisible ? (
            <div style={{ height: '60px', width: '60px', backgroundColor: 'white', borderWidth: '10px', padding: "10px", boxShadow: "0px 4px 10px 0px rgb(0 0 0 / 15%)", cursor: "pointer", borderColor: 'black', bottom: "2rem", right: "2rem", position: 'fixed', zIndex: 999, borderRadius: "50%" }} onClick={scrollToTop}>
                <img src={require('../../assets/images/arrow.png')} style={{ objectFit: 'contain', height: '100%', width: '100%', }} />
            </div >
        ) : null
    )
}

export default ScrollToTopIcon