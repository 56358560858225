import { createSlice } from "@reduxjs/toolkit"
import { generalApiService } from "../../Apis/General"
import { toast } from "react-toastify"
import { bannerApiService } from "../../Apis/Banner"
import { cartApiService } from "../../Apis/Cart"

let initialState = {
    general: null,
    cart: 0,
    banners: [],
    filters: {
        category: null,
        subcategory: null,
        brand: null,
        type: null
    }
}

export const GeneralSlice = createSlice({
    name: 'generalSlice',
    initialState,
    reducers: {
        changeFilter: (state, action) => {
            state.filters = { ...state.filters, ...action.payload }
            return state
        },
        clearFilter: (state) => {
            state.filters.category = null
            state.filters.subcategory = null
            state.filters.brand = null
            state.filters.type = null
        },
        addToCart: (state, action) => {
            let cart = state?.cart ? state?.cart : []
            let temp = [...cart, ...action?.payload]
            // temp.push(action.payload)
            state.cart = temp
            toast.success("Items Added to Cart")
        },
        emptyCart: (state) => {
            state.cart = []
        },
        updateCart: (state, action) => {
            const { index, val } = action.payload
            let cart = [...state?.cart]
            cart[index].qty = val
            state.cart = cart
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(generalApiService.endpoints.getGeneral.matchFulfilled, (state, action) => {
            let { success, data } = action.payload
            if (success) {
                state.general = data
            }
        })
        builder.addMatcher(bannerApiService.endpoints.getBanner.matchFulfilled, (state, action) => {
            let { success, data } = action.payload
            if (success) {
                state.banners = data
            }
        })
        builder.addMatcher(cartApiService.endpoints.getCart.matchFulfilled, (state, action) => {
            let { success, data } = action.payload
            if (success) {
                state.cart = data?.length || 0
            }
        })
    }
})

export const { changeFilter, clearFilter, addToCart, emptyCart, updateCart } = GeneralSlice.actions
export default GeneralSlice.reducer
