import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyGetProductsQuery } from '../../redux/Apis/Product';
import './index.css';

const SlideProductSearch = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const [getProducts, { data }] = useLazyGetProductsQuery()

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    getProducts({ search: term, currentPage: 1, itemsPerPage: 3 })
    setShowDropdown(term.length > 0);
  };

  return (
    <div>
      <div id="search-panel">
        <div className="container">
          <form id="search-expand" className="clearfix" method="post" action="#">
            <input
              id="search-field"
              type="text"
              className="site-input"
              name="search"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="submit" value="Submit">
              <i className="fas fa-search" />
            </button>
          </form>
        </div>

        {showDropdown && (
          <div className="dropdown search-dropdown w-100">
            {data?.data?.map((product) => (
              <div key={product.id} className="d-flex align-items-center w-400 gap-3">
                <div className="flex-shrink-0">
                  <img src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${product?.images[0]}`} alt="" className="img-fluid table-img" />
                </div>
                <div className="flex-grow-1">
                  <Link to={`/products/${product?._id}`} onClick={() => setShowDropdown(false)}>
                    <p className="p-md mb-0 medium">
                      {/* {data?.name} */}
                      {product.name}
                    </p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideProductSearch;