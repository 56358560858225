import { Link } from "react-router-dom"

const Footer = () => {

    let year = new Date().getFullYear()

    return (
        <footer className="pt-md-5 pt-4 pb-2">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 position-relative">
                        <div className="footer-border" />
                    </div>
                    <div className="col-lg-3 col-sm-6 my-3">
                        <a href="index.php">
                            <img
                                src="../assets/images/footer-logo.png"
                                alt=""
                                className="img-fluid footer-logo mt-0"
                            />
                        </a>
                        <p>
                            At Vapebae We Source Products From Multiple Warehouses So You Can Get AccessTo Everything You Need At One Place.
                        </p>
                        <ul className="ps-0 d-flex social-icons align-items-center mt-4">
                            <li className="me-3">
                                <a href="https://www.facebook.com/profile.php?id=61555993592562&mibextid=LQQJ4d" target="_blank">
                                    <i className="fab p-md fa-facebook-f" />
                                </a>
                            </li>
                            <li className="me-3">
                                <a href="https://www.instagram.com/vapebaeus?igsh=aHZoemVmb2I4Ympn&utm_source=qr" target="_blank">
                                    <i className="fab p-md fa-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-3 ms-auto col-md-6 my-3">
                                <h4 className="my-2 text-uppercase">Pages</h4>
                                <ul className="ps-0 text-uppercase mt-4">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="mt-2">
                                        <Link to="/brands">Brands</Link>
                                    </li>
                                    <li className="mt-2">
                                        <Link to="/products">Products</Link>
                                    </li>
                                    <li className="mt-2">
                                        <Link to="#_">About Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 my-3">
                                <h4 className="my-2 text-uppercase">Contact us</h4>
                                <ul className="ps-0 mt-4">
                                    <li className="d-flex align-items-center gap-2">
                                        <div className="flex-shrink-0 footer-contact-icon">
                                            <i className="fas fa-phone-alt p-lg green-text" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <a href="tel:+14709308814">+1 (470) 930-8814</a>
                                        </div>
                                    </li>
                                    <li className="mt-2 d-flex align-items-center gap-2">
                                        <div className="flex-shrink-0 footer-contact-icon">
                                            <i className="fas fa-envelope p-lg green-text" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <a href="mailto:support@vapebae.us">support@vapebae.us</a>
                                        </div>
                                    </li>
                                    {/* <li className="mt-2 d-flex align-items-center gap-2">
                                        <div className="flex-shrink-0 footer-contact-icon">
                                            <i className="fas fa-map-marker-alt p-lg green-text" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <a href="#_">Somewhere on earth</a>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 my-3">
                                <h4 className="my-2 text-uppercase">Quick Links</h4>
                                <ul className="ps-0 text-uppercase mt-4">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="mt-2">
                                        <Link to="/brands">Brands</Link>
                                    </li>
                                    <li className="mt-2">
                                        <Link to="/products">Products</Link>
                                    </li>
                                    <li className="mt-2">
                                        <Link to="#_">About Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <hr className="footer-line" />
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={require('../../assets/images/internativelogo.png')} className="cursor-pointer" style={{ height: '60px', marginRight: '20px' }} onClick={() => window.open("https://internativelabs.com", "_blank")} />
                            <p className="mt-3">Copyright © {year}, Created by <Link target={"_blank"} to="https://internativelabs.com">Internative Labs.</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer