import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/user/`

export const userApiService = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token

            headers.set('Authorization', `Bearer ${token}`)

            return headers
        }
    }),
    endpoints: (builder) => ({
        changePassword: builder.mutation({
            query: (args) => {

                return ({
                    url: `change-password`,
                    method: 'POST',
                    body: args
                })

            }
        }),
        editProfile: builder.mutation({
            query: (args) => {
                return ({
                    url: `update`,
                    method: 'PUT',
                    body: args
                })
            }
        }),
    })
})

export const {
    useChangePasswordMutation,
    useEditProfileMutation
} = userApiService